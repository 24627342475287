@font-face {
  font-family: "Space Grotesk";
  src: url("/public/fonts/SpaceGrotesk.ttf") format("truetype");
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("/public/fonts/Inter.ttf") format("truetype");
  font-weight: 100 900;
  font-style: normal;
}
