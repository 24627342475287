@tailwind base;
@tailwind components;
@tailwind utilities;

.btn-gradient {
  background: linear-gradient(to right, #ff9600, #f44001);
  border-radius: 20px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.quick-view-div {
  border-radius: 10px;
  margin: auto;
  background-size: cover;
  height: auto;
  min-height: 150px;
  padding: 4%;
  padding-top: 10%;
  background-color: white;
  display: inline-block;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 1;
  position: absolute;
  text-align: center;
  top: calc(92% + 20px);
  left: 50%;
  transform: translateX(-50%);
}
@media only screen and (max-width: 600px) {
  .filter-space-responsive {
    width: 100%;
    margin-top: 30%;
    border-radius: 20px 20px 0px 0px;
    padding: 5%;
  }
}

.custom-img-container {
  max-width: 100%;
  margin: auto;
  height: auto;
  display: block;
  margin: 5px auto;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}
.product-detailsh3 {
  font-family: Inter;
  font-size: 40px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  line-height: 1.5ch;
}
.share-logo {
  width: 40px;
  height: 40px !important;
  margin: 10px !important;
}

#filterButton {
  display: none;
}
.product-table th {
  background-color: #f2f2f2;
  color: #333;
}
.product-table th,
.product-table td {
  padding: 10px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  #filterButton {
    display: block;
  }
  .product-table th,
  .product-table td {
    font-size: 10px;
  }
  .td-productcondition {
    display: none;
  }
}
.magnifier-image {
  max-width: 55vw;
  height: 320px;
}

.magnifying-glass {
  cursor: none;
}

.animation {
  background-color: #6f6f6f;
  animation-iteration-count: infinite;
  animation-name: placeHolderimgShimmers; /* Corrected the name to match @keyframes */
  animation-timing-function: linear;
  animation-duration: 8s;
  background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
  background-size: 800px 104px;
  border-radius: 40px;
}

@keyframes placeHolderimgShimmers {
  0% {
    background-position: -600px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.font-inter {
  font-family: Inter;
}
